'use strict';

//libs
import $ from 'jquery';
global.$ = global.jQuery = $;

import { Tooltip, Toast, Popover } from 'bootstrap';

//style
import 'bootstrap/dist/css/bootstrap.min.css';
require('../css/app.css');

const App = function() {


	//public
	return {
		init: function() {
			console.log('*** App init ***');
		}
	};
}();

$(document).ready(function() {
	App.init();
});

export default App;